import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables"

// Components
import Container from "components/container/"

// Icons
import Icon1 from "assets/icons/how-it-works/icon-talent-discovery-1.inline.svg"
import Icon2 from "assets/icons/how-it-works/icon-talent-discovery-2.inline.svg"
import Icon3 from "assets/icons/how-it-works/icon-talent-discovery-3.inline.svg"
import Icon4 from "assets/icons/how-it-works/icon-talent-discovery-4.inline.svg"

const StyledTalentDiscovery = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .talent-discovery__icons {
    padding-top: 60px;

    ${breakpoint.medium`
      padding: 120px 0;
    `}

    .item {
      position: relative;
      margin-bottom: 60px;

      &::before {
        content: "";
        width: 0;
        height: 44px;
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        right: 0;
        margin: auto;
        // background-color: ${colors.navy__dark};
        border: 1px dotted ${colors.navy__dark};
        border-style: hidden dotted hidden hidden;

        ${breakpoint.small`
          width: 64px;
          height: 0;
          top: 30px;
          left: calc(100% - 16px);
          border-style: dotted hidden hidden hidden;
        `}
      }

      &::after {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        top: calc(100% + 8px + 18px);
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        background-color: ${colors.navy__dark};

        ${breakpoint.small`
          top: 28.5px;
          left: calc(100% + 14px);
          right: auto;
        `}
      }

      &--no-border {
        &::before,
        &::after {
          display: none;
        }
      }

      ${breakpoint.small`
        margin-bottom: 0;
      `}
    }

    .item__icon {
      height: 72px;
    }

    p {
      max-width: 224px;
      font-family: ${fonts.secondary};
      margin-top: 24px;
      margin-right: auto;
      margin-left: auto;

      ${breakpoint.small`
        margin-top: 56px;
      `}
    }
  }

  .talent-discovery__candidates {
    padding: 24px;
    margin-top: 48px;
    border-radius: 20px;

    ${breakpoint.medium`
      padding: 32px;
      margin-top: 104px;
    `}
  }
`

const TalentDiscovery = () => {
  const data = [
    {
      icon: <Icon1 />,
      text: "Source quality candidates",
    },
    {
      icon: <Icon2 />,
      text: "Analyze and predict candidate retention",
    },
    {
      icon: <Icon3 />,
      text: "Uncover additional job matches",
    },
    {
      icon: <Icon4 />,
      text: <span>Engage and deliver ready-to-interview talent</span>,
    },
  ]

  return (
    <StyledTalentDiscovery id="talentDiscovery" className="bg--silver__pale">
      <Container>
        <motion.div
          initial={{ y: 32, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="row justify-content-center text-center"
        >
          <div className="col-12 col-sm-10 col-md-8">
            <h2 className="h2 maxi mb-2">Talent Discovery</h2>
            <p className="text--large">
              With the national staff shortage, it’s important to fill jobs
              efficiently with quality candidates. We use predictive analytics
              to focus our sourcing and recruitment efforts, increase candidate
              volume where you need it most, and suggest additional roles suited
              to each candidate.
            </p>
          </div>
        </motion.div>

        <div className="talent-discovery__icons text-center">
          <div className="row justify-content-center">
            {data.map((item, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.15 }}
                className="col-12 col-sm-2"
                key={item.text}
              >
                <div
                  className={`item ${
                    index === data.length - 1 && "item--no-border"
                  }`}
                >
                  <div className="item__icon">{item.icon}</div>
                  <p className="font-weight--700">{item.text}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.div
          initial={{ y: 32, scale: 0.95 }}
          whileInView={{ y: 0, scale: 1 }}
          viewport={{ once: true }}
          className="row justify-content-center"
        >
          <div className="col-12 col-sm-10 col-md-8">
            <StaticImage
              src="../../assets/images/how-it-works/talent-discovery-image.png"
              alt=""
            />
          </div>
        </motion.div>
      </Container>
    </StyledTalentDiscovery>
  )
}

export default TalentDiscovery
