import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { colors } from "utils/variables"
import Lottie from "components/lottie"
import Button from "components/button"

// Animation
import Bias from "assets/animations/how-it-works/bias-mitigation.json"
import DataModel from "assets/animations/how-it-works/data-model.json"
import AdversarialFairnes from "assets/animations/how-it-works/adversarial-fairnes.json"

const StyledBiasMitigation = styled.section`
  padding: 60px 0;
  background: ${colors.silver__pale};

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .bias__explanation {
    max-width: 736px;
    width: 100%;
    margin: 16px auto 80px auto;
  }

  .bias__data {
    .data {
      text-align: start;
    }
  }

  .bias__demonstration {
    padding: 24px;
    margin-top: 60px;
    border-radius: 20px;

    ${breakpoint.medium`
      padding: 50px;
      margin-top: 112px;
    `}
  }
`

const BiasMitigation = () => (
  <StyledBiasMitigation id="biasMitigation">
    <Container>
      <div>
        <h2 className="h2 maxi text-center">Bias Mitigation</h2>
        <p className="bias__explanation text--large text-center">
          Biased hiring hurts both candidates and organizations by keeping
          qualified candidates from positions where they would thrive. That's
          why our platform uses a machine learning technique known as
          adversarial fairness—to avoid making biased predictions that amplify
          social bias.
        </p>
      </div>

      <div className="bias__data row justify-content-between">
        <div className="data mb-5 mb-sm-0 col-12 col-sm-4">
          <Lottie animation={DataModel} />
          <div>
            <h3 className="h3 mb-2 mt-4">Data Models</h3>
            <p className="text--large">
              Our predictor model produces retention predictions for candidates,
              while our discriminator model analyzes the same data to identify
              demographic and related classification data.
            </p>
          </div>
        </div>

        <div className="data mb-5 mb-sm-0 col-12 col-sm-4">
          <Lottie animation={AdversarialFairnes} />
          <div>
            <h3 className="h3 mb-2 mt-4">Adversarial Fairness</h3>
            <p className="text--large">
              By pitting these models against one another, Adversarial Fairness
              removes biased data points while delivering accurate retention
              predictions.
            </p>
          </div>
        </div>
        <div className="data mb-5 mb-sm-0 col-12 col-sm-4">
          <Lottie animation={Bias} />
          <div>
            <h3 className=" h3 mb-2 mt-4">Unbiased Results</h3>
            <p className="text--large">
              The result is unbiased data, with recommended hires surpassing the
              EEOC’s compliance threshold to create a hiring pool that mirrors
              surrounding communities.
            </p>
          </div>
        </div>
      </div>

      <div className="bias__demonstration bg--white text-center">
        <p className="h2 mb-4 font-weight--700">Ready for a demonstration?</p>

        <Button type="link" level="primary" to="/contact-us">
          Contact us
        </Button>
      </div>
    </Container>
  </StyledBiasMitigation>
)

export default BiasMitigation
