import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo"

// Sections
import Hero from "sections/how-it-works/hero"
import TalentDiscovery from "sections/how-it-works/talent-discovery"
import RetentionPrediction from "sections/how-it-works/retention-prediction"
import BiasMitigation from "sections/how-it-works/bias-mitigation"

const HowItWorks = () => (
  <Layout showNewsletter={false}>
    <SEO
      title="How It Works | Predictive Analytics | Arena Analytics"
      description="Discover how Arena Analytics uses AI, machine learning, and predictive analytics in recruitment to take the guesswork out of hiring."
    />
    <Hero />
    <TalentDiscovery />
    <RetentionPrediction />
    <BiasMitigation />
  </Layout>
)

export default HowItWorks
