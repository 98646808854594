import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container/"
import Lottie from "components/lottie/"

// Animacion
import AnimationRetentionPrediction from "assets/animations/how-it-works/talent_discovery.json"

const StyledRetention = styled.section`
  padding: 60px 0;

  ${breakpoints.medium`
    padding: 120px 0;
  `}
`

const RetentionPrediction = () => (
  <StyledRetention id="retentionPrediction">
    <Container>
      <div className="row align-items-center justify-content-between">
        <div className="col-12 col-md-5 mb-4 mb-md-0">
          <Lottie animation={AnimationRetentionPrediction} />
        </div>
        <div className="col-12 col-md-6">
          <h2 className="h2 maxi">Retention Prediction</h2>
          <p className="text--large mt-4 mb-4">
            Turnover costs time, money, and resources—so how can you tell which
            applicants will stay in your company if you hire them?
            <br />
            <br />
            Our model collects and analyzes data from over 10,000 data points
            per applicant, including employer data, market and labor data, and
            our own Arena Analytics candidate experience.
            <br />
            <br />
            We then apply data science and machine learning tools to identify
            patterns and predict which candidates are most likely to be retained
            by the organization if hired.
          </p>
        </div>
      </div>
    </Container>
  </StyledRetention>
)

export default RetentionPrediction
